import React from "react"
import { graphql, Link } from "gatsby"
import styled from 'styled-components'

import SEO from "../components/seo"
import Layout from "../components/layout"
import { Spacer } from "../styles/layout"

export const pageQuery = graphql`
  query FourOFourQuery {
    bg: file(relativePath: { eq: "p404.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, maxHeight: 700, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
  }
  `;

const NotFound = ({ data }) => {

  const { bg } = data // data.markdownRemark holds your post data
  const bgImage = bg.childImageSharp.sizes.src
  return (
    <Layout className="not-found-page">
      <SEO title="Page not found" description="This page could not be found" />
      <Container bg={bgImage}>
        <div className="content">
          <h2>404</h2>
          <h4>Oops! Page not found</h4>
          <p>The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.</p>
          <Link to="/">Back To Home</Link>
          <Spacer height={50} />
        </div>
      </Container>
    </Layout>
  )
}

export default NotFound

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${props => props.bg}) #151729;
.content {
  max-width: 600px;
  text-align: center;
}
h2 {
  font-size: 18vw;
  color: #fff;
  line-height: 1em;
}
h4 {
  position: relative;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #111;
  background: #fff;
  font-weight: 300;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 3px;
}

p {
  color: #fff;
  font-size: 1.2em;
}

a {
  position: relative;
  display: inline-block;
  padding: 10px 25px;
  background: #ff0562;
  color: #fff;
  text-decoration: none;
  margin-top: 25px;
  border-radius: 25px;
  border-bottom: 4px solid #d00d56;
}
`;